import { type Instance, types } from "mobx-state-tree";

export const PlayerModel = types
  .model({
    selectedSource: types.string,
  })
  .actions((self) => ({
    selectSource(source: string) {
      self.selectedSource = source;
    },
  }));

export type PlayerModelType = Readonly<Instance<typeof PlayerModel>>;
