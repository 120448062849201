import { cast, flow, getEnv, type Instance, types } from "mobx-state-tree";

import { type Env } from "@/app/store/store.js";
import type { TmdbPopularMovies } from "@/shared/api/tmdb/types.js";
import { BasePaginatedQueryModel } from "@/shared/model/base-paginated-query-model.js";
import {
  TmdbMovieModel,
  TmdbMovieModelType,
} from "@/shared/model/tmdb-movie-model.js";

const tmdbPopularMoviesResultsToTmdbMovieModels = (
  results: TmdbPopularMovies["results"],
): TmdbMovieModelType[] => {
  return results.map((movie) => cast<TmdbMovieModelType>(movie));
};

export const PopularMoviesModel = BasePaginatedQueryModel.named(
  "PopularMoviesModel",
)
  .props({
    tmdbData: types.array(TmdbMovieModel),
  })
  .actions((self) => ({
    getData: flow(function* (
      region: string,
      language: string,
      preloadedData: { tmdbPopularMovies: TmdbPopularMovies | null },
    ) {
      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";

      if (preloadedData.tmdbPopularMovies) {
        self.tmdbData = cast(
          tmdbPopularMoviesResultsToTmdbMovieModels(
            preloadedData.tmdbPopularMovies.results,
          ),
        );
        self.fetchStatus = "success";
        return;
      }

      try {
        const tmdbPopularMoviesResponse: TmdbPopularMovies = yield getEnv<Env>(
          self,
        ).tmdbClient.getPopularMovies(
          { page: self.pagination.nextPage, region },
          language,
        );

        const newData = tmdbPopularMoviesResultsToTmdbMovieModels(
          tmdbPopularMoviesResponse.results,
        );

        self.tmdbData.push(...newData);

        self.pagination.nextPage = tmdbPopularMoviesResponse.page + 1;
        self.pagination.totalPages = tmdbPopularMoviesResponse.total_pages;
        self.pagination.totalResults = tmdbPopularMoviesResponse.total_results;

        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
    resetData(): void {
      self.tmdbData = cast([]);
    },
  }));

export type PopularMoviesModelType = Instance<typeof PopularMoviesModel>;
