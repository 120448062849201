import { cast, flow, getEnv, type Instance, types } from "mobx-state-tree";

import { type Env } from "@/app/store/store.js";
import { BaseQueryModel } from "@/shared/model/base-query-model.js";

import {
  type SourcesResponse,
  sourceType,
} from "../lib/api/viewbox-source/types.js";
import { KodikSourceModel } from "./kodik-source-model.js";

const sourcesResponseToSourcesModelSources = (
  sourcesResponse: SourcesResponse,
): SourcesModelType["sources"] => {
  return cast(sourcesResponse.sources);
};

export const SourcesModel = BaseQueryModel.named("SourcesModel")
  .props({
    sources: types.array(
      types.model({
        name: types.string,
        type: types.enumeration(Object.values(sourceType)),
        iframe: types.model({
          url: types.string,
        }),
      }),
    ),
    // Отдельно обрабатываются сезоны и озвучки
    kodik: KodikSourceModel,
  })
  .views((self) => ({
    get firstSource() {
      return self.sources?.[0];
    },
    get preloadHrefs() {
      return self.sources.map(({ iframe: { url: iframeUrl } }) => {
        const url = new URL(iframeUrl);
        const href = `${url.protocol}//${url.hostname}`;
        return href;
      });
    },
  }))
  .actions((self) => ({
    getData: flow(function* (tmdbMediaType: "movie" | "tv", tmdbId: number) {
      if (self.fetchStatus === "loading") {
        return;
      }

      self.fetchStatus = "loading";

      try {
        const viewBoxSourcesResponse = yield getEnv<Env>(
          self,
        ).viewBoxSourceClient.getSourcesByTmdb(tmdbMediaType, tmdbId);

        self.sources = sourcesResponseToSourcesModelSources(
          viewBoxSourcesResponse,
        );
        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
    resetData() {
      self.fetchStatus = "idle";
      self.sources = cast([]);
    },
  }));

export type SourcesModelType = Instance<typeof SourcesModel>;
