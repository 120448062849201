import { cast, flow, getEnv, type Instance, types } from "mobx-state-tree";

import { type Env } from "@/app/store/store.js";
import type { TmdbTrendingTVSeries } from "@/shared/api/tmdb/types.js";
import { BasePaginatedQueryModel } from "@/shared/model/base-paginated-query-model.js";
import { TmdbTVSeriesModel } from "@/shared/model/tmdb-tv-series-model.js";

import { responseResultsToModels } from "../lib/mappers/response-results-to-models.js";

export const ThisWeekTrendingTVSeriesModel = BasePaginatedQueryModel.named(
  "ThisWeekTrendingTVSeriesModel",
)
  .props({
    tmdbData: types.array(TmdbTVSeriesModel),
  })
  .actions((self) => ({
    getData: flow(function* (
      language: string,
      preloadedData: { tmdbTrendingTVSeries: TmdbTrendingTVSeries | null },
    ) {
      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";

      if (preloadedData.tmdbTrendingTVSeries) {
        self.tmdbData = cast(
          responseResultsToModels(preloadedData.tmdbTrendingTVSeries.results),
        );
        self.fetchStatus = "success";
        return;
      }

      try {
        const tmdbTrendingTVSeriesResponse: TmdbTrendingTVSeries =
          yield getEnv<Env>(self).tmdbClient.getTrendingTVSeries(
            "week",
            language,
          );

        const newData = responseResultsToModels(
          tmdbTrendingTVSeriesResponse.results,
        );

        self.tmdbData.push(...newData);

        self.pagination.nextPage = tmdbTrendingTVSeriesResponse.page + 1;
        self.pagination.totalPages = tmdbTrendingTVSeriesResponse.total_pages;
        self.pagination.totalResults =
          tmdbTrendingTVSeriesResponse.total_results;

        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
    resetData(): void {
      self.tmdbData = cast([]);
    },
  }));

export type ThisWeekTrendingTVSeriesModelType = Instance<
  typeof ThisWeekTrendingTVSeriesModel
>;
