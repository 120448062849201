import { cast, flow, getEnv, type Instance, types } from "mobx-state-tree";

import type { TmdbMovie } from "@/shared/api/tmdb/types.js";
import { BaseQueryModel } from "@/shared/model/base-query-model.js";
import {
  TmdbMovieModel,
  TmdbMovieModelType,
} from "@/shared/model/tmdb-movie-model.js";

export const MoviePageModel = BaseQueryModel.named("MoviePageModel")
  .props({
    tmdbData: types.maybe(TmdbMovieModel),
  })
  .actions((self) => ({
    getData: flow(function* (
      id: number,
      language: string,
      preloadedData: { tmdbMovie: TmdbMovie | null },
    ) {
      if (preloadedData.tmdbMovie) {
        self.tmdbData = cast(preloadedData.tmdbMovie);
        self.fetchStatus = "success";
        return;
      }

      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";
      try {
        const tmdbMovieResponse: TmdbMovie = yield getEnv(
          self,
        ).tmdbClient.getMovie(id, language);
        self.tmdbData = cast<TmdbMovieModelType>(tmdbMovieResponse);
        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
    resetData(): void {
      self.tmdbData = undefined;
    },
  }));

export type MoviePageModelType = Instance<typeof MoviePageModel>;
