import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";

import type { Env } from "@/app/store/store.js";
import { KodikSearch } from "@/shared/api/kodik/types.js";

import { BaseSourceModel } from "./base-source-model.js";

const KodikSeasonsModel = types
  .model({
    seasons: types.array(
      types.model({
        title: types.string,
        shikimoriId: types.string,
        translations: types.array(
          types.model({
            name: types.string,
            iframeUrl: types.string,
          }),
        ),
      }),
    ),
    season: types.number,
    translation: types.number,
  })
  .views((self) => ({
    get selectedSeasonTranslations() {
      return self.seasons?.[self.season]?.translations;
    },
    get iframeUrl() {
      return self.seasons?.[self.season]?.translations?.[self.translation]
        .iframeUrl;
    },
  }))
  .actions((self) => ({
    setSeason(season: number) {
      self.season = season;
    },
    setTranslation(translation: number) {
      self.translation = translation;
    },
  }));

type KodikSeasonsModelType = Instance<typeof KodikSeasonsModel>;

type KodikSeason = {
  readonly shikimoriId: string;
  readonly title: string;
  readonly translations: Array<{ name: string; iframeUrl: string }>;
};

const kodikSearchResponseToKodikResultsModel = (
  kodikSearchResponse: KodikSearch,
): KodikSeasonsModelType => {
  const sortedResults = kodikSearchResponse.results.toSorted((a, b) => {
    if (a.last_season > b.last_season) {
      return 1;
    }

    if (a.last_season < b.last_season) {
      return -1;
    }

    return 0;
  });

  const seasons: KodikSeason[] = sortedResults.reduce(
    (acc, seasonAndTranslation) => {
      const seasonIndex = acc.findIndex(
        ({ shikimoriId }) => shikimoriId === seasonAndTranslation.shikimori_id,
      );

      if (seasonIndex === -1) {
        acc.push({
          shikimoriId: seasonAndTranslation.shikimori_id,
          title: seasonAndTranslation.title,
          translations: [
            {
              name: seasonAndTranslation.translation.title,
              iframeUrl: seasonAndTranslation.link,
            },
          ],
        });
      } else {
        acc[seasonIndex].translations.push({
          name: seasonAndTranslation.translation.title,
          iframeUrl: seasonAndTranslation.link,
        });
      }

      return acc;
    },
    [] as KodikSeason[],
  );

  return cast<KodikSeasonsModelType>({
    seasons,
    season: 0,
    translation: 0,
  });
};

export const KodikSourceModel = types
  .compose(BaseSourceModel, KodikSeasonsModel)
  .named("KodikSourceModel")
  .extend((self) => ({
    actions: {
      getData: flow(function* (imdbId: string) {
        if (self.fetchStatus === "loading") {
          return;
        }

        self.fetchStatus = "loading";

        try {
          const kinopoiskId =
            yield getEnv<Env>(self).wikidataClient.getKinopoiskIdByImdbId(
              imdbId,
            );

          const kodikSearchResponse =
            yield getEnv<Env>(self).kodikClient.search(kinopoiskId);

          const model =
            kodikSearchResponseToKodikResultsModel(kodikSearchResponse);

          self.seasons = model.seasons;
          self.season = model.season;
          self.translation = model.translation;

          self.fetchStatus = "success";
        } catch {
          self.fetchStatus = "error";
        }
      }),
      resetData() {
        self.fetchStatus = "idle";
        self.iframe = cast({});
        self.season = 0;
        self.translation = 0;
      },
    },
  }));
