import { cast } from "mobx-state-tree";

import type { TmdbTrendingMovies } from "@/shared/api/tmdb/types";
import type { TmdbMovieModelType } from "@/shared/model/tmdb-movie-model";

export const tmdbTrendingMoviesResultsToTmdbMovieModels = (
  results: TmdbTrendingMovies["results"],
): TmdbMovieModelType[] => {
  return results.map((movie) => cast<TmdbMovieModelType>(movie));
};
