import { cast, flow, getEnv, types } from "mobx-state-tree";

import { type Env } from "@/app/store/store.js";
import type { TmdbSeriesDiscoverResult } from "@/shared/api/tmdb/types.js";
import { BasePaginatedQueryModel } from "@/shared/model/base-paginated-query-model.js";
import {
  TmdbTVSeriesModel,
  TmdbTVSeriesModelType,
} from "@/shared/model/tmdb-tv-series-model.js";

const tmdbDiscoverTVSeriesResultsToTmdbTVSeriesModels = (
  results: TmdbSeriesDiscoverResult["results"],
): TmdbTVSeriesModelType[] => {
  return results.map((result) => cast<TmdbTVSeriesModelType>(result));
};

export const TVSeriesGenresPageModel = BasePaginatedQueryModel.named(
  "TVSeriesGenresPageModel",
)
  .props({
    tmdbData: types.array(TmdbTVSeriesModel),
    genreIds: types.maybe(types.string),
  })
  .actions((self) => ({
    getMoreData: flow(function* (genreIds: string, language: string) {
      if (self.genreIds !== undefined && self.genreIds !== genreIds) {
        self.tmdbData = cast([]);
        self.resetQuery();
        self.resetPagination();
      }
      self.genreIds = genreIds;

      if (self.isLoading) {
        return;
      }
      self.fetchStatus = "loading";

      try {
        const response: TmdbSeriesDiscoverResult = yield getEnv<Env>(
          self,
        ).tmdbClient.discoverSeries({
          with_genres: genreIds,
          page: self.pagination.nextPage,
          language,
        });

        const newData = tmdbDiscoverTVSeriesResultsToTmdbTVSeriesModels(
          response.results,
        );

        self.tmdbData.push(...newData);

        self.pagination.nextPage = response.page + 1;
        self.pagination.totalPages = response.total_pages;
        self.pagination.totalResults = response.total_results;

        self.fetchStatus = "success";
      } catch (err) {
        self.fetchStatus = "error";
      }
    }),
    resetData() {
      self.tmdbData = cast([]);
      self.resetQuery();
      self.resetPagination();
    },
  }));
