import { cast, flow, getEnv, type Instance, types } from "mobx-state-tree";

import { type Env } from "@/app/store/store.js";
import type { TmdbPopularTVSeries } from "@/shared/api/tmdb/types.js";
import { BasePaginatedQueryModel } from "@/shared/model/base-paginated-query-model.js";
import {
  TmdbTVSeriesModel,
  TmdbTVSeriesModelType,
} from "@/shared/model/tmdb-tv-series-model.js";

const tmdbPopularTVSeriesResultsToTmdbTVSeriesModels = (
  results: TmdbPopularTVSeries["results"],
): TmdbTVSeriesModelType[] => {
  return results.map((tv) => cast<TmdbTVSeriesModelType>(tv));
};

export const PopularTVSeriesModel = BasePaginatedQueryModel.named(
  "PopularTVSeriesModel",
)
  .props({
    tmdbData: types.array(TmdbTVSeriesModel),
  })
  .actions((self) => ({
    getData: flow(function* (
      region: string,
      language: string,
      preloadedData: { tmdbPopularTVSeries: TmdbPopularTVSeries | null },
    ) {
      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";

      if (preloadedData.tmdbPopularTVSeries) {
        self.tmdbData = cast(
          tmdbPopularTVSeriesResultsToTmdbTVSeriesModels(
            preloadedData.tmdbPopularTVSeries.results,
          ),
        );
        self.fetchStatus = "success";
        return;
      }

      try {
        const tmdbPopularTVSeriesResponse: TmdbPopularTVSeries =
          yield getEnv<Env>(self).tmdbClient.getPopularTVSeries(
            { page: self.pagination.nextPage, region },
            language,
          );

        const newData = tmdbPopularTVSeriesResultsToTmdbTVSeriesModels(
          tmdbPopularTVSeriesResponse.results,
        );

        self.tmdbData.push(...newData);

        self.pagination.nextPage = tmdbPopularTVSeriesResponse.page + 1;
        self.pagination.totalPages = tmdbPopularTVSeriesResponse.total_pages;
        self.pagination.totalResults =
          tmdbPopularTVSeriesResponse.total_results;

        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
    resetData(): void {
      self.tmdbData = cast([]);
    },
  }));

export type PopularTVSeriesModelType = Instance<typeof PopularTVSeriesModel>;
