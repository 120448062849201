import { type ISimpleType, types } from "mobx-state-tree";

export const TmdbPaginatedModel = <T extends ISimpleType<unknown>>(
  itemType: T,
) =>
  types.model("TmdbPaginated", {
    page: types.number,
    results: types.array(itemType),
    total_pages: types.number,
    total_results: types.number,
  });
