import { cast } from "mobx-state-tree";

import type { TmdbTrendingTVSeries } from "@/shared/api/tmdb/types.js";
import type { TmdbTVSeriesModelType } from "@/shared/model/tmdb-tv-series-model.js";

export const responseResultsToModels = (
  results: TmdbTrendingTVSeries["results"],
): TmdbTVSeriesModelType[] => {
  return results.map((movie) => cast<TmdbTVSeriesModelType>(movie));
};
