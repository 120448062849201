import { types } from "mobx-state-tree";

import { loadingStatus } from "./loading-status";

export const BaseQueryModel = types
  .model({
    fetchStatus: loadingStatus,
  })
  .views((self) => ({
    get isNeededToLoad() {
      return self.fetchStatus === "idle";
    },
    get isLoading() {
      return self.fetchStatus === "loading";
    },
    get isSuccess() {
      return self.fetchStatus === "success";
    },
    get isError() {
      return self.fetchStatus === "error";
    },
  }))
  .actions((self) => ({
    resetQuery() {
      self.fetchStatus = "idle";
    },
  }));
