import { cast, flow, getEnv, type Instance, types } from "mobx-state-tree";

import { type Env } from "@/app/store/store.js";
import type { TmdbTrendingMovies } from "@/shared/api/tmdb/types.js";
import { BasePaginatedQueryModel } from "@/shared/model/base-paginated-query-model.js";
import { TmdbMovieModel } from "@/shared/model/tmdb-movie-model.js";

import { tmdbTrendingMoviesResultsToTmdbMovieModels } from "../lib/tmdb-trending-movies-results-to-tmdb-movie-models.js";

export const ThisWeekTrendingMoviesModel = BasePaginatedQueryModel.named(
  "ThisWeekTrendingMoviesModel",
)
  .props({
    tmdbData: types.array(TmdbMovieModel),
  })
  .actions((self) => ({
    getData: flow(function* (
      language: string,
      preloadedData: { tmdbTrendingMovies: TmdbTrendingMovies | null },
    ) {
      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";

      if (preloadedData.tmdbTrendingMovies) {
        self.tmdbData = cast(
          tmdbTrendingMoviesResultsToTmdbMovieModels(
            preloadedData.tmdbTrendingMovies.results,
          ),
        );
        self.fetchStatus = "success";
        return;
      }

      try {
        const tmdbTrendingMoviesResponse: TmdbTrendingMovies =
          yield getEnv<Env>(self).tmdbClient.getTrendingMovies(
            "week",
            language,
          );

        const newData = tmdbTrendingMoviesResultsToTmdbMovieModels(
          tmdbTrendingMoviesResponse.results,
        );

        self.tmdbData.push(...newData);

        self.pagination.nextPage = tmdbTrendingMoviesResponse.page + 1;
        self.pagination.totalPages = tmdbTrendingMoviesResponse.total_pages;
        self.pagination.totalResults = tmdbTrendingMoviesResponse.total_results;

        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
    resetData(): void {
      self.tmdbData = cast([]);
    },
  }));

export type ThisWeekTrendingMoviesModelType = Instance<
  typeof ThisWeekTrendingMoviesModel
>;
