import { types } from "mobx-state-tree";

import { BaseQueryModel } from "./base-query-model";

export const BasePaginatedQueryModel = BaseQueryModel.named(
  "BasePaginatedQueryModel",
)
  .props({
    pagination: types.model({
      nextPage: types.number,
      totalPages: types.maybe(types.number),
      totalResults: types.maybe(types.number),
    }),
  })
  .actions((self) => ({
    resetPagination() {
      self.pagination.nextPage = 1;
      self.pagination.totalPages = undefined;
      self.pagination.totalResults = undefined;
    },
  }));
