import { cast, flow, getEnv, types } from "mobx-state-tree";

import type { TmdbTVSeries } from "@/shared/api/tmdb/types.js";
import { BaseQueryModel } from "@/shared/model/base-query-model.js";
import { TmdbTVSeriesModel } from "@/shared/model/tmdb-tv-series-model.js";

export const TVSeriesPageModel = BaseQueryModel.named("TVSeriesPageModel")
  .props({
    tmdbData: types.maybe(TmdbTVSeriesModel),
  })
  .actions((self) => ({
    getData: flow(function* (
      id: number,
      language: string,
      preloadedData: { tmdbTVSeries: TmdbTVSeries | null },
    ) {
      if (preloadedData.tmdbTVSeries) {
        self.tmdbData = cast(preloadedData.tmdbTVSeries);
        self.fetchStatus = "success";
        return;
      }

      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";
      try {
        const tmdbTVSeriesResponse: TmdbTVSeries = yield getEnv(
          self,
        ).tmdbClient.getTVSeries(id, language);
        self.tmdbData = cast(tmdbTVSeriesResponse);
        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
    resetData(): void {
      self.tmdbData = undefined;
    },
  }));
