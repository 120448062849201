export const sourceType = {
  collaps: "COLLAPS",
  videocdn: "VIDEOCDN",
  cdnmovies: "CDNMOVIES",
  kodik: "KODIK",
  hdvb: "HDVB",
  alloha: "ALLOHA",
  vibix: "VIBIX",
  turbo: "TURBO",
} as const;

type SourceType = typeof sourceType;

type HeadersArray = Array<{
  readonly name: string;
  readonly value: string;
}>;

type Source = {
  readonly name: string;
  readonly type: SourceType[keyof SourceType];
  readonly iframe: {
    readonly url: string;
    readonly headers: HeadersArray;
  };
};

export type SourcesResponse = {
  readonly sources: Source[];
};
