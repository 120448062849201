import { type Instance, types } from "mobx-state-tree";

export const WatchHistoryRecordModel = types.model({
  id: types.number,
  title: types.string,
  tmdbMediaType: types.enumeration(["movie", "tv"]),
  tmdbBackdropPath: types.string,
  createdAt: types.string,
});

export type WatchHistoryRecordModelType = Readonly<
  Instance<typeof WatchHistoryRecordModel>
>;
