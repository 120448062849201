import { type Instance, types } from "mobx-state-tree";

export const TmdbTVSeriesModel = types.model("TmdbTVSeries", {
  id: types.number,
  adult: types.optional(types.boolean, false),
  backdrop_path: types.maybeNull(types.string),
  first_air_date: types.string,
  last_air_date: types.maybe(types.string),
  genre_ids: types.array(types.number),
  name: types.string,
  origin_country: types.array(types.string),
  original_language: types.string,
  original_name: types.string,
  overview: types.string,
  tagline: types.maybe(types.string),
  popularity: types.number,
  poster_path: types.maybeNull(types.string),
  vote_average: types.number,
  vote_count: types.number,
  genres: types.frozen(),
  videos: types.frozen(),
  production_countries: types.frozen(),
  external_ids: types.frozen(),
});

export type TmdbTVSeriesModelType = Instance<typeof TmdbTVSeriesModel>;
